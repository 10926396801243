import React, { FC, useState, useContext, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { Switch } from "formik-material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { ReactNode } from "react-transition-group/node_modules/@types/react";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(20, 0, 6),
    paddingTop: "0",
  },
  dividerTitle: {
    backgroundColor: "#06141f",
    width: "33%",
    marginBottom: "0px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  url: {
    color: "#7c7c7c",
    textDecoration: "none",
    "&:hover": {
      color: "#1f2532",
      textDecoration: "none",
    },
  },
  detailsParagraph: {
    textAlign: "left",
    fontSize: "1.8rem",
  },
  link: {
    backgroundColor: "transparent",
    marginBottom: "1rem",
    color: "#3f51b5",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.8,
    },
    //fontWeight: '700',
    fontWeight: 700,
  },
  typoHeading: {
    textAlign: "center",
    color: "textPrimary",
    fontWeight: 500,
  },
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },

  button: {
    width: "100%",
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: "uppercase",
  },
  noSideDetails: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface Props {
  base_uri: string | null;
  dealerId: number | null;
  dealers?: any[];
  hasMultiDealers: boolean;
  models?: any[];
  serviceType: "standard";
  sideBarDetailsHeader?: string | null;
  sideBarDetails?: ReactNode | null;
  leadTypeId?: number;
  dealerName: string;
}

interface LeadInput {
  leadStatusId?: number;
  dealerId?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  leadSourceId?: number;
  TypeCode?: string;
  notes?: string;
  leadTypeId?: number;
  dateUpdated?: string;
}

interface LeadVehicleInput {
  LeadId?: number;
  VariantId?: number;
}

const Service: FC<Props> = ({
  base_uri,
  dealerName,
  dealerId,
  dealers,
  models,
  serviceType,
  leadTypeId,
  sideBarDetailsHeader,
  hasMultiDealers,
  sideBarDetails,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [contactMe, setContactMe] = useState(false);
  const [tsAndCs, setTsAndCs] = useState(false);

  let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  const values = {
    dealerId: "",
    name: "",
    phone: "",
    email: "",
    regNo: "",
    date: "",
    modelId: "",
    notes: "",
    modelName: "",
    dealerName: "",
  };
  const DEALERNAME = "DealerNamePlaceholder";

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required").matches(phoneRegExp, "Phone number is not valid"),
  });

  const checkValid = (validForm: any) => {
    return validForm && tsAndCs;
  };

  const createVehicleLead = (values: any) => {
    let vehicleParam: LeadVehicleInput = {};
    vehicleParam.LeadId = values?.leadId;
    vehicleParam.VariantId = values?.variantId;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: "POST",
      url: `${base_uri}/leadVehicles`,
      data: vehicleParam,
      cancelToken: source.token,
    })
      .then((responseSecond) => {
        setLoader(false);
        enqueueSnackbar(`Successfully sent information`, { variant: "success" });
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        enqueueSnackbar("Unable to get complete the request", { variant: "error" });
        //setLoader(false);
      });
  };

  function createLead(values: any) {
    setLoader(true);

    let params: LeadInput = {};

    params.leadStatusId = 1;
    params.dealerId = !hasMultiDealers ? dealerId : values.dealerId;
    params.name = values.name;
    params.firstName = values.name;
    params.lastName = "";
    params.phoneNumber = values.phone;
    params.emailAddress = values.email;
    params.leadSourceId = 1;
    params.leadTypeId = leadTypeId;
    params.TypeCode = "NEW";
    params.notes = values.notes;

    params.dateUpdated = new Date().toISOString();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: "POST",
      url: `${base_uri}/leads`,
      data: params,
      cancelToken: source.token,
    })
      .then((response) => {
        values.leadId = response.data.id;
        createVehicleLead(values);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        enqueueSnackbar("Unable to complete the request", { variant: "error" });
        setLoader(false);
      });
  }

  const handleSelectChange = (e: any, value: any, setFieldValue: Function, fieldName: any, fieldId: any) => {
    setFieldValue(fieldName, e.target.value);
    setFieldValue(fieldId, value.props.id);
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Typography gutterBottom variant="h5" className={classes.typoHeading}>
          Book A Service
        </Typography>
        <Divider className={classes.dividerTitle} />
        <Grid container spacing={2} className={sideBarDetailsHeader !== null ? classes.noSideDetails : ""}>
          {sideBarDetailsHeader && (
            <Grid item xs={12} md={5}>
              <Typography gutterBottom variant="h5" className={classes.typoHeading}>
                {sideBarDetailsHeader}
              </Typography>
              <p>{sideBarDetails}</p>
            </Grid>
          )}
          <Grid item xs={12} md={5}>
            <React.Fragment>
              <MuiThemeProvider>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Card className={classes.content} elevation={0}>
                      <CardContent>
                        <Formik
                          isInitialValid={false}
                          initialValues={values}
                          validationSchema={validationSchema}
                          onSubmit={async (values, actions) => {
                            createLead(values);
                          }}
                        >
                          {(props) => {
                            let {
                              values: { name, phone, email },
                              touched,
                              errors,
                              handleBlur,
                              isValid,
                              submitForm,
                              setFieldTouched,
                              setFieldValue,
                            } = props;
                            return (
                              <Form>
                                <Grid container /*className={classes.root}*/ direction="row">
                                  <Grid item /*className={classes.stepPaddingButton}*/ xs={12} md={12}>
                                    <TextField
                                      id="name"
                                      name="name"
                                      label="Name"
                                      value={name}
                                      fullWidth
                                      variant="outlined"
                                      helperText={touched.name ? errors.name : ""}
                                      error={touched.name && Boolean(errors.name)}
                                      onChange={(event) => {
                                        setFieldValue("name", event.target.value);
                                        setFieldTouched("name", true, false);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                    <TextField
                                      id="email"
                                      name="email"
                                      type="email"
                                      label="Email"
                                      fullWidth
                                      value={email}
                                      variant="outlined"
                                      helperText={touched.email ? errors.email : ""}
                                      error={touched.email && Boolean(errors.email)}
                                      onChange={(event) => {
                                        setFieldValue("email", event.target.value);
                                        setFieldTouched("email", true, false);
                                      }}
                                    />{" "}
                                  </Grid>
                                  <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                    <TextField
                                      id=" phone"
                                      name="phone"
                                      type="tel"
                                      label="Cellphone"
                                      fullWidth
                                      value={phone}
                                      variant="outlined"
                                      helperText={touched.phone ? errors.phone : ""}
                                      error={touched.phone && Boolean(errors.phone)}
                                      onChange={(event) => {
                                        setFieldValue("phone", event.target.value);
                                        setFieldTouched("phone", true, false);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                    <TextField
                                      id="regNo"
                                      name="regNo"
                                      label="Registration No."
                                      fullWidth
                                      variant="outlined"
                                      helperText={touched.regNo ? errors.regNo : ""}
                                      error={touched.regNo && Boolean(errors.regNo)}
                                      onChange={(event) => {
                                        setFieldValue("regNo", event.target.value);
                                        setFieldTouched("regNo", true, false);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                    <Typography gutterBottom variant="caption" className={classes.typoHeading}>
                                      Preferred date
                                    </Typography>
                                    <TextField
                                      id="date"
                                      name="date"
                                      variant="outlined"
                                      fullWidth
                                      type="date"
                                      helperText={touched.date ? errors.date : ""}
                                      error={touched.date && Boolean(errors.date)}
                                      onChange={(event) => {
                                        setFieldValue("date", event.target.value);
                                        setFieldTouched("date", true, false);
                                      }}
                                    ></TextField>
                                  </Grid>
                                  <Grid item className={classes.stepPaddingButton} xs={12}>
                                    <TextField
                                      variant="outlined"
                                      fullWidth
                                      id="modelId"
                                      select
                                      //required
                                      label="Which model is your car?"
                                      value={values?.modelName}
                                      helperText={errors.modelId && touched.modelId ? errors.modelId : ""}
                                      error={touched.modelId && Boolean(errors.modelId)}
                                      onBlur={handleBlur("modelId")}
                                      //@ts-ignore
                                      onChange={(e: any, child: any) =>
                                        handleSelectChange(e, child, setFieldValue, "modelName", "modelId")
                                      }
                                    >
                                      {models?.map((option: any) => (
                                        <MenuItem key={option.modelId} id={option.modelId} value={option.name}>
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  {hasMultiDealers && (
                                    <Grid item className={classes.stepPaddingButton} xs={12}>
                                      <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="dealerId"
                                        select
                                        //required
                                        label="Dealership"
                                        value={values?.dealerName}
                                        helperText={errors.dealerId && touched.dealerId ? errors.dealerId : ""}
                                        error={touched.dealerId && Boolean(errors.dealerId)}
                                        onBlur={handleBlur("dealerId")}
                                        //@ts-ignore
                                        onChange={(e: any, child: any) =>
                                          handleSelectChange(e, child, setFieldValue, "dealerName", "dealerId")
                                        }
                                      >
                                        {dealers?.map((option: any) => (
                                          <MenuItem key={option.id} id={option.id} value={option.name}>
                                            {option.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                  )}

                                  <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                    <Typography gutterBottom variant="caption" className={classes.typoHeading}>
                                      Notes
                                    </Typography>

                                    <Box mb={2}>
                                      <TextField
                                        id="notes"
                                        name="notes"
                                        type="text"
                                        placeholder="enter notes here"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="standard"
                                        helperText={touched.notes ? errors.notes : ""}
                                        error={touched.notes && Boolean(errors.notes)}
                                        onChange={(event) => {
                                          setFieldValue("notes", event.target.value);
                                          setFieldTouched("notes", true, false);
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item className={classes.flex} xs={12} md={12} lg={12}>
                                    <FormControlLabel
                                      label=""
                                      control={
                                        <Field
                                          component={Switch}
                                          color="primary"
                                          type="checkbox"
                                          name="contactMe"
                                          onClick={() => {
                                            setContactMe(contactMe ? false : true);
                                          }}
                                        />
                                      }
                                    />
                                    <span>I allow {DEALERNAME} Motors to contact me</span>
                                  </Grid>

                                  <Grid item className={classes.flex} xs={12} md={12} lg={12}>
                                    <FormControlLabel
                                      label=""
                                      control={
                                        <Field
                                          component={Switch}
                                          color="primary"
                                          type="checkbox"
                                          name="popi"
                                          onClick={() => {
                                            setTsAndCs(tsAndCs ? false : true);
                                          }}
                                        />
                                      }
                                    />
                                    <span>
                                      By submitting this form I agree to the{" "}
                                      <Link component={RouterLink} to="/terms" className={classes.link}>
                                        terms and conditions
                                      </Link>{" "}
                                      and{" "}
                                      <Link component={RouterLink} to="/privacy" className={classes.link}>
                                        privacy policies.
                                      </Link>
                                    </span>
                                  </Grid>
                                  <Grid container direction="row">
                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                      {!loader && (
                                        <Button
                                          className={classes.button}
                                          variant={"contained"}
                                          color="primary"
                                          disabled={!checkValid(isValid)}
                                          onClick={() => {
                                            submitForm();
                                          }}
                                        >
                                          {"Contact Me"}
                                        </Button>
                                      )}
                                      {loader == true && (
                                        <Button
                                          className={classes.button}
                                          variant={"contained"}
                                          color="primary"
                                          disabled={true}
                                        >
                                          <CircularProgress />
                                        </Button>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Form>
                            );
                          }}
                        </Formik>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </MuiThemeProvider>
            </React.Fragment>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Service;
