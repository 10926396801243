import React, { FC, useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },
  button: {
    width: "100%",
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: "uppercase",
    color: "red",
  },
  success: {
    color: "black",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  icon: {
    fontSize: "3rem",
  },
}));

interface Props {
  base_uri: string;
  dealerId: number;
  dealerType?: "single_dealer" | "multi_dealer" | "multi_region_dealer";
  pqType?: "no_pq" | "mfc" | "compuscan" | "interim_pq";
  applicationSubmission?: boolean;
  applicationUrl?: string;
  dealers?: Dealer[];
  activeDealerId?: number;
  masterDealerId?: number;
  purchasePrice?: number;
  mmCode?: string;
  variantId?: number;
  ownedModelId?: number;
  offerId?: number;
  colorId?: number;
  stockId?: number;
  leadTypeId?: number;
  financeQuestions?: any[];
  includeValidationChecks?: boolean;
  setPQResult?: Function;
  pqSuccessUrl?: string;
  pqFailUrl?: string;
  onSubmitForm?: Function;
}

interface Dealer {
  dealerId: any;
  name: any;
}

interface Question {
  name: string;
  labelComponent: any;
  correct: boolean;
  order: number;
  answer?: boolean;
}

const Finance: FC<Props> = ({
  base_uri,
  dealerId,
  dealerType = "single_dealer",
  applicationSubmission = true,
  applicationUrl,
  dealers,
  activeDealerId,
  masterDealerId,
  pqType,
  purchasePrice,
  mmCode,
  variantId,
  ownedModelId,
  colorId,
  stockId,
  offerId,
  leadTypeId,
  financeQuestions,
  includeValidationChecks = false,
  setPQResult,
  pqSuccessUrl,
  pqFailUrl,
  onSubmitForm,
}) => {

  const classes: any = useStyles();

  const defaultFinanceQuestions = [
    {
      name: "licenseCheck",
      labelComponent: <span>I have a valid South African Driver's License</span>,
      correct: true,
      order: 1,
    },
    {
      name: "creditCheck",
      labelComponent: <span>I consent to a credit bureau check</span>,
      correct: true,
      order: 2,
    },
    {
      name: "underDebtReview",
      labelComponent: <span>I confirm that I am not under debt review</span>,
      correct: true,
      order: 3,
    },
    {
      name: "popi",
      labelComponent: (
        <span>
          By submitting this form I agree to the{" "}
          <Link component={RouterLink} to="/terms" className={classes.link}>
            terms and conditions
          </Link>{" "}
          and{" "}
          <Link component={RouterLink} to="/privacy" className={classes.link}>
            privacy policies.
          </Link>
        </span>
      ),
      correct: true,
      order: 4,
    },
  ];

  const defaultValidationChecks = [
    {
      name: "checkA",
      labelComponent: <span>I am not a minor</span>,
      correct: true,
      order: 1,
    },
    {
      name: "checkB",
      labelComponent: <span>I have never been declared mentally unfit by a court</span>,
      correct: true,
      order: 2,
    },
    {
      name: "checkC",
      labelComponent: <span>I am not subject to an administration order</span>,
      correct: true,
      order: 3,
    },
    {
      name: "checkD",
      labelComponent: <span>I do not have any current debt re-arrangement in existence</span>,
      correct: true,
      order: 4,
    },
    {
      name: "checkE",
      labelComponent: <span>I have not previously applied for a debt re-arrangement</span>,
      correct: true,
      order: 5,
    },
    {
      name: "checkF",
      labelComponent: (
        <span>
          I do not have applications pending for credit, nor do I have any open quotations under section 92 of the
          National Credit Act 34 of 2005 ("National Credit Act")
        </span>
      ),
      correct: true,
      order: 6,
    },
    {
      name: "checkG",
      labelComponent: <span>I am not currently in debt counselling</span>,
      correct: true,
      order: 7,
    },
    {
      name: "checkH",
      labelComponent: <span>I consent to the Vehicle Finance Terms & Conditions</span>,
      correct: true,
      order: 8,
    },
  ];

  if (!financeQuestions) {
    financeQuestions = defaultFinanceQuestions;

    if (includeValidationChecks) {
      financeQuestions.push(...defaultValidationChecks);
    }
  }

  const [questionAnswers, setQuestionAnswers] = useState<Question[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = useState(false);
  const [delearName, setDelearName] = useState(undefined);
  const [delearDetails, setDelearDetails] = useState<any>(undefined);

  const [loader, setLoader] = useState(false);

  let idRegex: any = `(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))`;
  let phoneRegExp: any = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  var yupRules: any = {
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required").matches(phoneRegExp, "Phone number is not valid"),
  };

  if (!pqType || pqType != "no_pq") {
    yupRules = {
      ...yupRules,
      id: Yup.string().required("ID number is required").matches(idRegex, "ID Number invalid"),
    };
  }

  let validationSchema = Yup.object(yupRules);

  useEffect(() => {
    let f = dealers?.filter((d) => d.dealerId !== masterDealerId);

    setDelearName(f?.filter((d) => d.dealerId === activeDealerId)[0]?.name);

    if (dealerId !== masterDealerId) {
      setDelearDetails(dealers?.filter((d) => d.dealerId == dealerId)[0]);
    } else {
    }
  }, [dealers, activeDealerId, dealerId]);

  let values = {
    id: "",
    netSalary: "",
    totalExpenses: "",
    name: "",
    lastName: "",
    phone: "",
    email: "",
    dealerId: delearDetails?.dealerId || activeDealerId || dealerId,
    dealer: delearDetails?.name || delearName || dealerId,
  };

  const checkValid = (validForm: boolean, values: any) => {
    if (!validForm || !values) return false;

    let allChecked = true;

    if (financeQuestions) {
      for (var idx in financeQuestions) {
        var question = financeQuestions[idx];
        var questionVal = values[question.name] ?? false;

        if (question.correct != questionVal) {
          allChecked = false;
          break;
        }
      }
    }

    return validForm && allChecked;
  };

  const handleSelectChange = (e: any, value: any, setFieldValue: any, fieldName: any, fieldId: any) => {
    setFieldValue(fieldName, e.target.value);
    setFieldValue(fieldId, value.props.id);
  };

  function createLead(values: any) {
    var sourceId = 1; // Website

    if (document.referrer) {
      if (document.referrer.indexOf("facebook") > -1) {
        sourceId = 2; // Facebook
      }
    }

    setLoader(true);

    let params: any = {};

    params.leadStatusId = 1;
    params.dealerId = dealerType == "single_dealer" ? dealerId : values.dealerId;
    params.name = values.name + " " + values.lastName;
    params.firstName = values.name;
    params.lastName = values.lastName;
    params.phoneNumber = values.phone;
    params.emailAddress = values.email;
    params.leadSourceId = sourceId;
    params.leadTypeId = 12;   // Finance Application
    params.TypeCode = stockId ? "USED" : variantId ? "NEW" : "NoVehicle";
    params.dateUpdated = new Date().toISOString();

    //Lead Vehicle
    params.mmCode = mmCode ? mmCode : "";
    params.variantId = variantId ? variantId : 0;
    params.ownedModelId = ownedModelId ? ownedModelId : 0;
    params.stockId = stockId ? stockId : 0;
    params.offerId = offerId ? offerId : 0;

    params.ownedVariantExteriorsId = colorId ? colorId : 0;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: "POST",
      url: `${base_uri}/leads`,
      data: params,
      cancelToken: source.token,
    })
      .then((response: any) => {
        values.leadId = response.data.id;

        if (pqType && pqType == "no_pq") {
          if (applicationSubmission && applicationUrl) {
            window.location.href = applicationUrl.replace(":leadId", values.leadId);
          }

          enqueueSnackbar(`Successfully submitted your information`, { variant: "success" });
          setSuccess(true);
          setLoader(false);

          return;
        }

        submitForPQ(values);
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) return;

        enqueueSnackbar("Unable to complete the request", { variant: "error" });
        setLoader(false);
      });
  }

  function submitForPQ(values: any) {

    let params: any = {
      idNumber: values.id,
      leadId: parseInt(values.leadId),
      netSalary: parseInt(values.netSalary),
      totalExpenses: parseInt(values.totalExpenses),
      desiredAmount: purchasePrice ? purchasePrice : 162900,
      underDebtReview: !values.underDebtReview,
      licenseCheck: values.licenseCheck,
    };

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    let url = `${base_uri}/applications/pq`;

    axios({
      method: "POST",
      url: url,
      data: params,
      cancelToken: source.token,
    })
      .then((response: any) => {
        // @ts-ignore
        fbq("track", "Lead"); // eslint-disable-line

        setLoader(false);

        if (setPQResult != null) {
          setPQResult(response?.data);
        } else {
          window.location.href = !response?.data.includes("pqDecline")
            ? pqSuccessUrl?.replace(":leadId", values.leadId) || response.data
            : pqFailUrl?.replace(":leadId", values.leadId) || response.data;
        }
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) return;

        enqueueSnackbar(`Unable to complete request. ${error}`, { variant: "error" });
        setLoader(false);
      });
  }

  return (
    <React.Fragment>
      {/* @ts-ignore */}
      <MuiThemeProvider>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Card className={classes.content} elevation={0}>
              <CardContent>
                <Formik
                  initialValues={values}
                  isInitialValid={false}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={async (values: any) => {
                    createLead(values);

                    if (onSubmitForm) {
                      onSubmitForm(values);
                    }
                  }}
                >
                  {(props: any) => {
                    const {
                      values,
                      touched,
                      errors,
                      isValid,
                      handleBlur,
                      handleChange,
                      setFieldTouched,
                      setFieldValue,
                    } = props;
                    return (
                      <Form noValidate>
                        <Grid container spacing={2} className={classes.root} direction="column">
                          {/* Input fields */}
                          <Grid item xs={12} container spacing={2}>
                            {(!pqType || pqType != "no_pq") && (
                              <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={4}>
                                <TextField
                                  id="id"
                                  name="id"
                                  label="Your SA ID"
                                  required
                                  value={values.id}
                                  fullWidth
                                  variant="outlined"
                                  helperText={touched.id ? errors.id : ""}
                                  error={touched.id && Boolean(errors.id)}
                                  onChange={handleChange}
                                />
                              </Grid>
                            )}

                            <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={4}>
                              <TextField
                                id="name"
                                name="name"
                                label="First Name"
                                required
                                value={values.name}
                                fullWidth
                                variant="outlined"
                                helperText={touched.name ? errors.name : ""}
                                error={touched.name && Boolean(errors.name)}
                                onChange={handleChange}
                              />
                            </Grid>

                            <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={4}>
                              <TextField
                                id="lastName"
                                name="lastName"
                                label="Last Name"
                                required
                                value={values.lastName}
                                fullWidth
                                variant="outlined"
                                helperText={touched.lastName ? errors.lastName : ""}
                                error={touched.lastName && Boolean(errors.lastName)}
                                onChange={handleChange}
                              />
                            </Grid>

                            <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={4}>
                              <TextField
                                id="email"
                                name="email"
                                type="email"
                                label="Email"
                                fullWidth
                                required
                                value={values.email}
                                variant="outlined"
                                helperText={touched.email ? errors.email : ""}
                                error={touched.email && Boolean(errors.email)}
                                onChange={handleChange}
                              />
                            </Grid>

                            <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={4}>
                              <TextField
                                id="phone"
                                name="phone"
                                type="tel"
                                label="Cellphone"
                                fullWidth
                                required
                                value={values.phone}
                                variant="outlined"
                                helperText={touched.phone ? errors.phone : ""}
                                error={touched.phone && Boolean(errors.phone)}
                                onChange={handleChange}
                              />
                            </Grid>

                            {(!pqType || pqType != "no_pq") && (
                              <>
                                <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={4}>
                                  <TextField
                                    id="netSalary"
                                    name="netSalary"
                                    label="Your Nett Salary"
                                    value={values.netSalary}
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.netSalary ? errors.netSalary : ""}
                                    error={touched.netSalary && Boolean(errors.netSalary)}
                                    onChange={handleChange}
                                  />
                                </Grid>

                                <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={4}>
                                  <TextField
                                    id="totalExpenses"
                                    name="totalExpenses"
                                    label="Your Total Expenses"
                                    value={values.totalExpenses}
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.totalExpenses ? errors.totalExpenses : ""}
                                    error={touched.totalExpenses && Boolean(errors.totalExpenses)}
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </>
                            )}

                            {dealerType == "multi_dealer" && (
                              <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={4}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  id="dealer"
                                  select={
                                    delearName !== undefined ? false : true && 
                                    delearDetails !== undefined ? false : true
                                  }
                                  label="Dealership"
                                  value={values.dealer}
                                  helperText={errors.dealer && touched.dealer ? errors.dealer : ""}
                                  error={errors.dealer && touched.dealer}
                                  onBlur={handleBlur("dealer")}
                                  /* @ts-ignore */
                                  onChange={(e: any, child: any) =>
                                    handleSelectChange(e, child, setFieldValue, "dealer", "dealerId")
                                  }
                                >
                                  {dealers?.map((option) => (
                                    <MenuItem key={option.dealerId} id={option.dealerId} value={option.name}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            )}
                          </Grid>

                          {/* Checkboxes */}
                          {financeQuestions && (
                            <Grid item xs={12} container spacing={1}>
                              {financeQuestions.map((itm: Question) => (
                                <Grid item className={classes.flex} xs={12} md={6}>
                                  <FormControlLabel
                                    control={<Checkbox name={itm.name} color="primary" onChange={handleChange} />}
                                    label={itm.labelComponent}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          )}

                          {/* Form action buttons */}
                          <Grid item xs={12} container spacing={2}>
                            <Grid item className={classes.stepPaddingButton} xs={12}>
                              <Button
                                className={classes.button}
                                variant={"contained"}
                                color="primary"
                                type="submit"
                                disabled={!checkValid(isValid, values) || loader === true}
                              >
                                {loader == true ? <CircularProgress /> : "Apply now"}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {pqType && pqType == "mfc" && (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center", padding: "10px 25px" }}>
              <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                You will be redirected to MFC for a brief moment to authenticate your session, don't worry you will come
                right back here. This is a necessary step to protect your information.
              </span>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", padding: "10px 25px" }}>
              <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                All calculations made on calculators supplied on this site, together with rates quoted, are guidelines
                only and are subject to confirmation at the time of finalising any transactions. All information
                regarding the products, fees and/or costs that are included in and form a fundamental basis of the
                calculations are subject to change at any time prior to a final pre-agreement quote being handed to the
                User. The User indemnifies Askon InspectaCar against any loss or liability, which the User may suffer as
                a result of the use of any calculator. The site and all information provided on this site and the
                services provided on this site, are provided "as is". The information provided on this site should not
                be treated as professional advice of any kind.
              </span>
            </Grid>
          </Grid>
        )}
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default Finance;
