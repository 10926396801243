import React, { useState, FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, Grid, TextField, Button } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { defaultCipherList } from "constants";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
  },
  btn: {
    padding: "5px 30px",
    //@ts-ignore
    backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: "white",
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
      opacity: 0.8,
    },
  },
}));

interface Props {
  personalDetails: any;
  onSubmit: Function;
  websiteColors?: any;
  setAtiveDealerId: Function;
  dealerList?: any[];
  defaultDealerId: any;
  rightSection?: any;
}

const PersonalDetailsForm: FC<Props> = ({
  personalDetails,
  onSubmit,
  dealerList,
  defaultDealerId,
  setAtiveDealerId,
  rightSection,
}) => {

  const classes = useStyles();
  const isDealerListAvailable = dealerList?.length && dealerList.length > 0;
  const defaultDealer = isDealerListAvailable
    ? dealerList?.find((d) => {
      return d?.dealerId === defaultDealerId;
    })
    : null;

  const [dealer, setDealer] = useState(defaultDealer);
  const initialFormValues = {
    firstName: personalDetails?.firstName || "",
    lastName: personalDetails?.lastName || "",
    phone: personalDetails?.phone || "",
    email: personalDetails?.email || "",
  };

  const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required").matches(phoneRegExp, "Phone number is not valid"),
  });

  const submitForm = (values: any) => {
    onSubmit(values);
  };
  const dealers = dealerList ? dealerList : [];
  return (
    <React.Fragment>
      <Card className={classes.holder}>
        <CardHeader
          className="p-4"
          title={"About you"}
          subheader={
            "In order to load your car we need to know a bit about you. This process will take under 5mins from start to completion."
          }
        />

        <CardContent className="px-4">
          <Formik
            isInitialValid={false}
            initialValues={initialFormValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {(props) => {
              const { values, touched, errors, isValid, handleBlur, handleChange, setFieldValue } = props;
              return (
                <Form>
                  <Grid item xs={12} md={4}>
                    {isDealerListAvailable === true && (
                      <TextField
                        fullWidth
                        label="Active Dealer"
                        required
                        select
                        value={dealer}
                        onChange={(e) => {
                          handleChange(e);
                          const value = e.target.value;
                          //@ts-ignore
                          setAtiveDealerId(value?.dealerId);
                          setDealer(value);
                        }}
                        onBlur={handleBlur}
                        name="activeDealer"
                        id="activeDealer"
                      >
                        {dealers.map((option, i) => (
                          <MenuItem key={i} value={option}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>

                  <Grid container spacing={4} direction="row">
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        value={values.firstName}
                        helperText={touched.firstName ? errors.firstName : ""}
                        error={touched.firstName && Boolean(errors.firstName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        value={values.lastName}
                        helperText={touched.lastName ? errors.lastName : ""}
                        error={touched.lastName && Boolean(errors.lastName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <TextField
                        id="phone"
                        name="phone"
                        label="Mobile Number"
                        variant="outlined"
                        fullWidth
                        value={values.phone}
                        helperText={touched.phone ? errors.phone : ""}
                        error={touched.phone && Boolean(errors.phone)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <TextField
                        id="email"
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        fullWidth
                        value={values.email}
                        helperText={touched.email ? errors.email : ""}
                        error={touched.email && Boolean(errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    {rightSection && (<Grid item xs={12} sm={6} md={4}>
                      {rightSection}
                    </Grid>)}

                    <Grid item xs={12} container justify="flex-end">
                      <Button className={classes.btn} variant="contained" color="primary" type="submit">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default PersonalDetailsForm;
