import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Grid, Button } from '@material-ui/core';
import * as Yup from "yup";
import { Formik, Form } from "formik";
import ImageUploaderInput from '../ImageUploader/ImageUploaderInput';

const useStyles = makeStyles((theme) => ({
    holder: {
        borderRadius: '5px !important'
    },
    btn: {
        padding:'5px 30px',
    }
}));

interface Props {
    images: any;
    onSubmit: Function;
}

const VehicleImageForm: FC<Props> = ({
    images,
    onSubmit
}) => {

    const classes = useStyles();

    const initialFormValues = {
        front: images?.front || '',
        rear: images?.rear || '',
        left: images?.left || '',
        right: images?.right || ''
    }

    const validationSchema = Yup.object({
        front: Yup.string().required("Front image is required"),
        rear: Yup.string().required("Rear image is required"),
        left: Yup.string().required("Left-side image is required"),
        right: Yup.string().required("Right-side image is required")
    });

    const submitForm = (values: any) => {
        onSubmit(values);
    };

    return (
        <React.Fragment>
            <Card className={classes.holder}>

                <CardHeader
                        className="p-4"
                        title={'Submit images / photos of your vehicle'}
                    />

                <CardContent className="px-4">
                    <Formik
                        isInitialValid={false}
                        initialValues={initialFormValues}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            submitForm(values)
                        }}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                isValid,
                                handleBlur,
                                handleChange,
                                setFieldValue
                            } = props;
                            return (
                                <Form>
                                    <Grid container spacing={2} direction="row">

                                        <Grid item xs={12} sm={6} md={4}>
                                            <ImageUploaderInput 
                                                title='Front'
                                                fieldName={'front'}
                                                error={errors.front && touched.front} />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <ImageUploaderInput 
                                                title='Rear'
                                                fieldName={'rear'}
                                                error={errors.rear && touched.rear} />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <ImageUploaderInput 
                                                title='Left Side'
                                                fieldName={'left'}
                                                error={errors.left && touched.left} />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <ImageUploaderInput 
                                                title='Right Side'
                                                fieldName={'right'}
                                                error={errors.right && touched.right} />
                                        </Grid>

                                        <Grid item xs={12} container justify="flex-end" >
                                            <Button className={classes.btn} variant='contained' color="primary" type="submit">
                                                Next
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </CardContent>

            </Card>
        </React.Fragment>
    );
};

export default VehicleImageForm;