import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Tabs, Tab } from "@material-ui/core";
import PersonalDetailsForm from "./PersonalDetailsForm";
import VehicleSpecsForm from "./VehicleSpecsForm";
import VehicleImageForm from "./VehicleImageForm";
import ConfirmationContainer from "./ConfirmationContainer";

const useStyles = makeStyles((theme) => ({
    tradeContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: theme.spacing(4, 0, 10),
    },
    contents: {
        maxWidth: "1280px",
        paddingLeft: "24px",
        paddingRight: "24px",
        color: "white",
    },
    btn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "2%",
        width: "100%",
        background: "#e30011",
        color: "white",
        fontWeight: "bold",
        fontSize: "20px",
        cursor: "pointer",
        padding: "15px 20px",
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        padding: "40px 0px",
    },
    red: {
        color: "#e30011",
        //, color:'#ed0000'
    },
    text: {
        textAlign: "center",
        padding: theme.spacing(2, 25, 6),
        fontSize: "1.0rem",
        display: "block",
        margin: "0 auto",
        color: "white",
        "@media (max-width: 426px)": {
            padding: theme.spacing(2, 5, 6),
        },
    },
    heading: {
        textAlign: "center",
        padding: theme.spacing(2, 0, 0),
        fontSize: "2.8rem",
    },
    primary: {
        //@ts-ignore
        background: ({ primaryColor }) => `${primaryColor} !important`,
    },
}));

interface Props {
    base_uri: string | null;
    wePay4Cars_uri: string | null;
    dealerId: number | null;
    leadSourceId?: number | null;
    websiteColors?: any;
    dealerList?: any[];
    personalDetailsRightSection?: any;
    resetForm?: boolean;
    setFocus?: string;
    onSubmitForm?: Function;
}

const SellYourVehicleWePay4Cars: FC<Props> = ({
    base_uri,
    wePay4Cars_uri,
    dealerId,
    leadSourceId = 15,
    websiteColors,
    dealerList,
    personalDetailsRightSection,
    resetForm,
    setFocus,
    onSubmitForm,
}) => {

    const classes = useStyles({ ...websiteColors });
    const [tabIndex, setTabIndex] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [personalDetailsFormData, setPersonalDetailsFormData] = useState({});
    const [vehicleSpecsFormData, setVehicleDetailsFormData] = useState({});
    const [vehicleImageFormData, setVehicleImageFormData] = useState({});
    const [activeDealerId, setActiveDealerId] = useState(dealerId);

    const submitPersonalDetails = (personalDetails: any) => {
        setPersonalDetailsFormData(personalDetails);
        setTabIndex(tabIndex + 1);
        scroll();
    };

    const submitVehicleSpecs = (vehicleSpecs: any) => {
        setVehicleDetailsFormData(vehicleSpecs);
        setTabIndex(tabIndex + 1);
        scroll();
    };

    const submitVehicleImages = (vehicleImages: any) => {
        setVehicleImageFormData(vehicleImages);
        setTabIndex(tabIndex + 1);
        scroll();
    };

    const submitForms = () => {
        if (resetForm && resetForm == true) {
            setTabIndex(0);
        }
        else {
            setIsSubmitted(true);
        }
        scroll();
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
        if (newTab != tabIndex) {
            setTabIndex(newTab);
        }
    };

    const scroll = ()=>{
        if(setFocus && !!setFocus){
          setTimeout(() => {
            var element = document.getElementById(setFocus);
            //@ts-ignore
            if(element) {
              element.scrollIntoView({behavior: "smooth"});
            }
          }, 500);
        }
    }

    const tabs = [
        {
            id: 0,
            label: "About you",
            comp: (
                <PersonalDetailsForm
                    personalDetails={personalDetailsFormData}
                    onSubmit={submitPersonalDetails}
                    websiteColors={websiteColors}
                    defaultDealerId={activeDealerId ? activeDealerId : dealerId}
                    rightSection={personalDetailsRightSection}
                    setAtiveDealerId={setActiveDealerId}
                    dealerList={dealerList}
                    wePay4Cars_uri={wePay4Cars_uri}
                />
            ),
        },
        {
            id: 1,
            label: "Vehicle Specs",
            comp: (
                <VehicleSpecsForm
                    vehicle={vehicleSpecsFormData}
                    onSubmit={submitVehicleSpecs}
                    base_uri={base_uri}
                    wePay4Cars_uri={wePay4Cars_uri}
                    websiteColors={websiteColors}
                />
            ),
        },
        { 
            id: 2, 
            label: "Photos", 
            comp: (
                <VehicleImageForm 
                    images={vehicleImageFormData} 
                    onSubmit={submitVehicleImages} 
                />
            )
        },
        {
            id: 3,
            label: "Vehicle Summary",
            comp: (
                <ConfirmationContainer
                    onSubmit={submitForms}
                    base_uri={base_uri}
                    dealerId={activeDealerId ? activeDealerId : dealerId}
                    leadSourceId={leadSourceId}
                    personalDetails={personalDetailsFormData}
                    vehicleSpecs={vehicleSpecsFormData}
                    vehicleImages={vehicleImageFormData}
                    websiteColors={websiteColors}
                    resetForm={resetForm}
                    setFocus={setFocus}
                    onSubmitForm={onSubmitForm}
                    wePay4Cars_uri={wePay4Cars_uri}
                />
            ),
        },
    ];

    return (
        <Container maxWidth="lg" className={classes.tradeContainer}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <div className="mb-4">
                        <Tabs variant="scrollable" scrollButtons="on" value={tabIndex} classes={{ indicator: classes.primary }}>
                            {tabs.map((step, index) => (
                                <Tab key={step.id} label={`Step ${index + 1} - ${step.label}`} onClick={(e: any) => handleTabChange(e, index)} />
                            ))}
                        </Tabs>
                    </div>
                </Grid>

                {isSubmitted && (
                    <Grid item xs={12} container justifyContent="center">
                        <Typography variant="h6" color="textSecondary">
                            Information successfully captured and submitted
                        </Typography>
                    </Grid>
                )}
                {!isSubmitted && (
                    <Grid item xs={12}>
                        {tabs.map((step, index) => (
                            <React.Fragment key={step.id}>{tabIndex === index ? step.comp : null}</React.Fragment>
                        ))}
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default SellYourVehicleWePay4Cars;
