import React, {FC, useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from "axios";
import Button from "@material-ui/core/Button";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Switch } from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from '@material-ui/core/Box';
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingBelow: {
        marginRight: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingButton: {
        marginTop: "0.5rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    flex: {
        display: "flex"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        minWidth: "100%",
        height: 550,
    },
    uppercase: {
        textTransform: 'uppercase'
    },
}));

interface Props {
    base_uri: string,
    dealerName: string,
    dealerId: number,
    dealerType?: "single_dealer" | "multi_dealer" | "multi_region_dealer",
    dealers?: [],
    isCashBuyer?: boolean,
    leadTypeId: number,
    variantId: number
}

const ContactUs: FC<Props> = ({ base_uri, dealerId, dealerName, dealerType = "single_dealer", dealers, isCashBuyer, leadTypeId, variantId }) => {
    const classes: any = useStyles();
    const [contactMe, setContactMe] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [loader, setLoader] = useState(false)

    let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

    const values = {
        name: '',
        phone: '',
        email: '',
        dealerId: dealerId
    }

    const validationSchema = Yup.object({
        name: Yup.string().required(
            "Name is required"
        ),
        email: Yup.string()
            .email("Enter a valid email")
            .required("Email is required"),
        phone: Yup.string()
            .required("Phone number is required")
            .matches(phoneRegExp, "Phone number is not valid")
    });

    const checkValid = (validForm: boolean) => {
        return (validForm && contactMe)
    }

    const createVehicleLead = (values: any) => {

        let vehicleParam: any = {}
        vehicleParam.LeadId = values.leadId;
        vehicleParam.VariantId = variantId;

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${base_uri}/leadVehicles`,
            data: vehicleParam,
            cancelToken: source.token
        }).then((responseSecond) => {
            setLoader(false);
            enqueueSnackbar(`Successfully sent information`, { variant: 'success' });

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get complete the request", { variant: 'error' });
            //setLoader(false);
        })
    }

    function createLead(values: any) {

        setLoader(true);

        let params: any = {}

        params.leadStatusId = 1
        params.dealerId = dealers && dealers.length > 0 ? values.dealerId : dealerId
        params.name = values.name
        params.firstName = values.name;
        params.lastName = '';
        params.phoneNumber = values.phone
        params.emailAddress = values.email
        params.leadSourceId = 1
        params.notes = isCashBuyer ? "Cash buyer" : ""
        params.leadTypeId = leadTypeId

        params.dateUpdated = new Date().toISOString();

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${base_uri}/leads`,
            data: params,
            cancelToken: source.token
        }).then((response) => {

            // @ts-ignore
            fbq('track', 'Lead');  // eslint-disable-line

            if (variantId) {
                values.leadId = response.data.id;

                createVehicleLead(values);
            }
            else {
                setLoader(false);
                enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
            }

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to complete the request", { variant: 'error' });
            setLoader(false);
        })
    }

    const handleSelectChange = (e: any, value: any, setFieldValue: any, fieldName: any, fieldId: any) => {
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, value.props.id)
    }

    return (
        <React.Fragment>
            <MuiThemeProvider>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Card className={classes.content} elevation={0}>
                            <CardContent>


                                <Formik
                                    isInitialValid={false}
                                    initialValues={values}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values, actions) => {
                                        createLead(values)
                                    }}
                                >

                                    {(props) => {
                                        let {
                                            values: {
                                                name,
                                                phone,
                                                email,
                                                dealerId
                                            },
                                            touched,
                                            errors,
                                            handleBlur,
                                            isValid,
                                            submitForm,
                                            setFieldTouched,
                                            setFieldValue,
                                        } = props;
                                        return (
                                            <Form>
                                                <Grid container className={classes.root} direction="row">
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    >
                                                        <TextField
                                                            id="name"
                                                            name="name"
                                                            label="Name"
                                                            value={name}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.name ? errors.name : ""}
                                                            error={touched.name && Boolean(errors.name)}
                                                            onChange={(event) => {
                                                                setFieldValue("name", event.target.value)
                                                                setFieldTouched("name", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12}>
                                                        <TextField
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            label="Email"
                                                            fullWidth
                                                            value={email}
                                                            variant="outlined"
                                                            helperText={touched.email ? errors.email : ""}
                                                            error={touched.email && Boolean(errors.email)}
                                                            onChange={(event) => {
                                                                setFieldValue("email", event.target.value)
                                                                setFieldTouched("email", true, false);
                                                            }}
                                                        />{" "}
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12}>
                                                        <TextField
                                                            id=" phone"
                                                            name="phone"
                                                            type="tel"
                                                            label="Cellphone"
                                                            fullWidth
                                                            value={phone}
                                                            variant="outlined"
                                                            helperText={touched.phone ? errors.phone : ""}
                                                            error={touched.phone && Boolean(errors.phone)}
                                                            onChange={(event) => {
                                                                setFieldValue("phone", event.target.value)
                                                                setFieldTouched("phone", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    {dealerType == "multi_dealer" &&
                                                        <Grid item className={classes.stepPaddingButton} xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                id="dealerId"
                                                                select
                                                                //required
                                                                label="Dealership"
                                                                value={values.dealerId}
                                                                helperText={errors.dealerId && touched.dealerId ? errors.dealerId : ''}
                                                                /* @ts-ignore */
                                                                error={errors.dealerId && touched.dealerId}
                                                            onBlur={handleBlur("dealerId")}
                                                            /* @ts-ignore */
                                                                onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'dealerId', 'dealerId')}
                                                            >
                                                            {dealers && dealers.map((option: any) => (
                                                                    <MenuItem key={option.id} id={option.id} value={option.name}>
                                                                        {option.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                    }
                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                        {/* @ts-ignore */}
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="contactMe"
                                                                    onClick={() => {
                                                                        setContactMe(contactMe ? false : true)
                                                                    }}
                                                                />
                                                            }

                                                        />
                                                        <span>I allow {dealerName} to contact me</span>
                                                    </Grid>

                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                        {/* @ts-ignore */}
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="popi"

                                                                />
                                                            }

                                                        />
                                                        <span>
                                                            By submitting this form I agree to the <Link component={RouterLink} to="/terms" className={classes.link}>terms and conditions</Link> and <Link component={RouterLink} to="/privacy" className={classes.link}>privacy policies.</Link>
                                                        </span>
                                                    </Grid>
                                                    <Grid container direction="row">
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>

                                                            {!loader &&
                                                                <Button className={classes.button} variant={"contained"}
                                                                    color="primary"
                                                                    disabled={!checkValid(isValid)}
                                                                    onClick={() => {
                                                                        submitForm();
                                                                    }}
                                                                >
                                                                    {isCashBuyer ? 'Order Now' : 'Contact Me'}
                                                                </Button>
                                                            }
                                                            {loader == true && (
                                                                <Button className={classes.button} variant={"contained"}
                                                                    color="primary"
                                                                    disabled={true}
                                                                >
                                                                    <CircularProgress />
                                                                </Button>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}

                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        </React.Fragment>
    );
}

export default ContactUs