import React, { FC, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Checkbox,
  Container
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from "yup";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import ImageUploaderInput from "../ImageUploader/ImageUploaderInput";

const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
  },
  flexHolder: {
    display: "flex",
    flexWrap: "wrap",
  },
  btn: {
    padding: "5px 30px",
    //@ts-ignore
    backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: "white",
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
      opacity: 0.8,
    },
  },
  legend: {},
  labels: {},
  radioButton: {},
}));

interface Props {
  base_uri: string | null;
  wePay4Cars_uri?: string | null;
  vehicle: any;
  onSubmit: Function;
  websiteColors?: any;
}

const VehicleSpecsForm: FC<Props> = ({ base_uri, wePay4Cars_uri, vehicle, onSubmit, websiteColors }) => {
  const classes = useStyles({ ...websiteColors });
  const localStyles = useStyles();
  const  {enqueueSnackbar}  = useSnackbar();

  const initialFormValues = {
    licenseDisk: vehicle?.licenseDisk || "",
    make: vehicle?.make || "",
    makeId: vehicle?.makeId || 0,
    model: vehicle?.model || "",
    modelId: vehicle?.modelId || 0,
    variant: vehicle?.variant || "",
    variantId: vehicle?.variantId || 0,
    variantCode: vehicle?.variantCode || "",
    variantStartYear: vehicle?.variantStartYear || 0,
    variantEndYear: vehicle?.variantEndYear || 0,
    year: vehicle?.year || "",
    odometer: vehicle?.odometer || "",
    color: vehicle?.color || "",
    vin: vehicle?.vin || "",
    regNo: vehicle?.regNo || "",
    transmission: vehicle?.transmission || "",
    fuelType: vehicle?.fuelType || "",
    spareKeys: vehicle?.spareKeys || false,
    serviceBook: vehicle?.serviceBook || false,
    maintenancePlan: vehicle?.maintenancePlan || false,
    warranty: vehicle?.warranty || false,
  };

  const [loading, setLoading] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [makes, setMakes] = useState<any>([]);
  const [models, setModels] = useState<any>([]);
  const [variants, setVariants] = useState<any>([]);
  const [variantIntroYear, setVariantIntroYear] = useState<number>(initialFormValues.variantStartYear);
  const [variantDiscYear, setVariantDiscYear] = useState<number>(initialFormValues.variantEndYear);

  const validationSchema = Yup.object({
    make: Yup.string().required("Select a vehicle make"),
    model: Yup.string().required("Select a vehicle model"),
    variant: Yup.string().required("Select a model variant"),
    year: Yup.number()
      .min(variantIntroYear, "Model year must be between variant introduction and discontinue years")
      .max(variantDiscYear, "Model year must be between variant introduction and discontinue years")
      .required("Model year is required"),
    odometer: Yup.number().required("Odometer reading is required"),
    regNo: Yup.string().required("Registration number is required"),
    transmission: Yup.string().required("Select a transmission"),
    fuelType: Yup.string().required("Select a fuel type"),
  });

  useEffect(() => {

    let errors: string[] = [];
    let allPromises: Promise<boolean>[] = [];

    allPromises.push(new Promise<boolean>((res) => {
      
      try {
        getMakes();
      } catch (error) {
        errors.push(`Failed to load the makes: ${error}`);
      }
      
      res(true);

    }));

    if (vehicle?.makeId > 0) {
      allPromises.push(new Promise<boolean>((res) => {

        try {
          getModels(vehicle.makeId);
        } catch (error) {
          errors.push(`Failed to load the models: ${error}`);
        }
        
        res(true);

      }));
    }

    if (vehicle?.modelId > 0) {
      allPromises.push(new Promise<boolean>((res) => {
        
        try {
          getVariants(vehicle.modelId);
        } catch (error) {
          errors.push(`Failed to load the variants: ${error}`);
        }
        
        res(true);

      }));
    }

    Promise.allSettled(allPromises)
      .then(() => {
        
        setLoading(false);

        if (errors.length > 0) {
          enqueueSnackbar(`Some errors occurred while loading the form data: ${errors.map(e => e)}`, { variant: 'error' });	
        }
        
      }).catch((error) => {
        
        setLoading(false);
        enqueueSnackbar(`An unexpected error occurred while loading the form data: ${error}`, { variant: 'error' });

      });

  }, []);

  const getMakes = async () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setLoadingOptions(true);

    axios
      .get(`${base_uri}/Makes`, {
        cancelToken: source.token,
      })
      .then((result) => {
        setLoadingOptions(false);
        setMakes(result?.data?.list || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(`Failed to load the makes: ${error}`, { variant: "error" });
      });
  };

  const getModels = async (makeId: number) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setLoadingOptions(true);

    axios
      .get(`${base_uri}/Models`, {
        params: {
          makeId: makeId,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        setLoadingOptions(false);
        setModels(result?.data?.list || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(`Failed to load the models: ${error}`, { variant: "error" });
      });
  };

  const getVariants = async (modelId: number) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setLoadingOptions(true);

    axios
      .get(`${base_uri}/Variants`, {
        params: {
          modelId: modelId,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        setLoadingOptions(false);
        setVariants(result?.data?.list || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(`Failed to load the variants: ${error}`, { variant: "error" });
      });
  };

  const checkLicense = (base64VehicleLicense: string, values: any, setFieldValue: Function) => {

    if (!wePay4Cars_uri) {
      return;
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setLoading(true);

    axios
      .post(
        `${wePay4Cars_uri}/vehicle/contactable`,
        { base64VehicleLicense },
        { cancelToken: source.token }
      )
      .then((response: any) => {
        
        if (!response?.data) {
          setLoading(false);
          enqueueSnackbar(`No information retrieved from reading the license disk`, { variant: "warning" });
          return;
        }

        setFieldValue("color", response?.data?.color || values.color);
        setFieldValue("regNo", response?.data?.registration || values.regNo);
        setFieldValue("vin", response?.data?.vin || values.vin);

        axios
          .get(`${base_uri}/models`, {
            params: {
              searchString: response?.data?.model,
            },
            cancelToken: source.token,
          })
          .then((result) => {
            
            let models = result?.data?.list;
            let model = models[0];
            let make = {
              id: model?.makeID,
              name: model?.make,
            };

            if (!model) {
              setLoading(false);
              enqueueSnackbar(`Could not determine the make or model from the license disk`, { variant: "warning" });
              return;
            }

            setFieldValue("make", model.make);
            setFieldValue("makeId", model.makeID);
            setFieldValue("model", model.title);
            setFieldValue("modelId", model.id);

            setMakes([make]);
            setModels(models);
            getVariants(model.id)
              .then(() => setLoading(false))
              .catch(() => setLoading(false));

          })
          .catch((error) => {
            setLoading(false);
            enqueueSnackbar(`Failed to get all the models: ${error}`, { variant: "warning" });
          });
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(`Failed to read the license disk: ${error}`, { variant: "warning" });
      });
  };

  const handleSelectChange = (e: any, value: any, setFieldValue: Function, fieldName: string, fieldId: string) => {
    setFieldValue(fieldName, e?.target?.value);
    setFieldValue(fieldId, value?.props?.id);
  };

  const submitForm = (values: any) => {
    onSubmit(values);
  };

  return (
    <React.Fragment>
      <Card className={classes.holder}>
        <CardHeader className="p-4" title={"About your vehicle"} />

        <CardContent className="px-4">
          <Formik
            isInitialValid={false}
            initialValues={initialFormValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {(props) => {
              const { values, touched, errors, isValid, handleBlur, handleChange, setFieldValue } = props;
              return (
                <Form>
                  <Grid container spacing={4} direction="row">

                    <Grid item xs={12} container justifyContent="flex-start">
                      <ImageUploaderInput
                        title="South African License Disk"
                        fieldName={"licenseDisk"}
                        onChange={(uri: string) => checkLicense(uri, values, setFieldValue)}
                        error={errors.licenseDisk && touched.licenseDisk}
                      />
                    </Grid>

                    {
                      loading 
                      &&
                      <Grid container justifyContent="center">
                        <CircularProgress />
                      </Grid>
                    }
                    {
                      !loading &&
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="make"
                            label="Make"
                            variant="outlined"
                            fullWidth
                            select
                            disabled={!values?.makeId && loadingOptions}
                            value={values.make}
                            helperText={errors.make && touched.make ? errors.make : ""}
                            error={touched.make && Boolean(errors.make)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "make", "makeId");

                              // Load the models for the selected make
                              setTimeout(() => {
                                getModels(child?.props?.id);
                              }, 100);
                            }}
                          >
                            {makes.map((option: any) => (
                              <MenuItem key={option.id} id={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="model"
                            label="Model"
                            variant="outlined"
                            fullWidth
                            select
                            disabled={(!values?.modelId && loadingOptions) || !values?.makeId}
                            value={values.model}
                            helperText={errors.model && touched.model ? errors.model : ""}
                            error={touched.model && Boolean(errors.model)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "model", "modelId");

                              // Load the variants for the selected model
                              setTimeout(() => {
                                getVariants(child?.props?.id);
                              }, 100);
                            }}
                          >
                            {models.map((option: any) => (
                              <MenuItem key={option.id} id={option.id} value={option.title}>
                                {option.title}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="variant"
                            label="Variant"
                            variant="outlined"
                            fullWidth
                            select
                            disabled={(!values?.variantId && loadingOptions) || !values?.modelId}
                            value={values.variant}
                            helperText={errors.variant && touched.variant ? errors.variant : ""}
                            error={touched.variant && Boolean(errors.variant)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "variant", "variantId");

                              // Set the variant introduction & discontinue years to validate the Year field
                              let variant = variants.find((v: any) => v.id == child?.props?.id);

                              setVariantIntroYear(variant?.introYear);
                              setVariantDiscYear(variant?.discontinueYear);

                              // Set the field values to be used in the final form
                              setFieldValue("variantCode", variant?.mmCode);
                              setFieldValue("variantStartYear", variant?.introYear);
                              setFieldValue("variantEndYear", variant?.discontinueYear);
                            }}
                          >
                            {variants.map((option: any) => (
                              <MenuItem key={option.id} id={option.id} value={option.variantName}>
                                {option.variantName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="year"
                            label="Year"
                            variant="outlined"
                            type="number"
                            fullWidth
                            disabled={(!values?.year && loadingOptions) || !values?.variantId}
                            value={values.year}
                            helperText={errors.year && touched.year ? errors.year : ""}
                            error={touched.year && Boolean(errors.year)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="odometer"
                            name="odometer"
                            label="Odometer Reading"
                            variant="outlined"
                            type="number"
                            fullWidth
                            value={values.odometer}
                            helperText={touched.odometer ? errors.odometer : ""}
                            error={touched.odometer && Boolean(errors.odometer)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="color"
                            name="color"
                            label="Color (Optional)"
                            variant="outlined"
                            fullWidth
                            value={values.color}
                            helperText={touched.color ? errors.color : ""}
                            error={touched.color && Boolean(errors.color)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="vin"
                            name="vin"
                            label="VIN Number"
                            variant="outlined"
                            fullWidth
                            value={values.vin}
                            helperText={touched.vin ? errors.vin : ""}
                            error={touched.vin && Boolean(errors.vin)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="regNo"
                            name="regNo"
                            label="Registration Number"
                            variant="outlined"
                            fullWidth
                            value={values.regNo}
                            helperText={touched.regNo ? errors.regNo : ""}
                            error={touched.regNo && Boolean(errors.regNo)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="transmission"
                            label="Transmission"
                            variant="outlined"
                            fullWidth
                            select
                            value={values.transmission}
                            helperText={errors.transmission && touched.transmission ? errors.transmission : ""}
                            error={touched.transmission && Boolean(errors.transmission)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "transmission", "transmissionId");
                            }}
                          >
                            {["Automatic", "Manual"].map((option: string, index: any) => (
                              <MenuItem key={index} id={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        {/* @ts-ignore */}
                        <Container item xs={12} sm={6} md={4} className={localStyles.flexHolder}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend" className={localStyles.legend}>
                                Fuel Type
                              </FormLabel>
                              <RadioGroup
                                aria-label="fuelType"
                                name="fuelType"
                                value={values.fuelType}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="Petrol"
                                  classes={{ label: localStyles.labels }}
                                  control={<Radio classes={{ checked: localStyles.radioButton }} />}
                                  label="Petrol"
                                />
                                <FormControlLabel
                                  value="Diesel"
                                  classes={{ label: localStyles.labels }}
                                  control={<Radio classes={{ checked: localStyles.radioButton }} />}
                                  label="Diesel"
                                />
                                <FormControlLabel
                                  value="Hybrid"
                                  classes={{ label: localStyles.labels }}
                                  control={<Radio classes={{ checked: localStyles.radioButton }} />}
                                  label="Hybrid"
                                />
                                <FormControlLabel
                                  value="Electric"
                                  classes={{ label: localStyles.labels }}
                                  control={<Radio classes={{ checked: localStyles.radioButton }} />}
                                  label="Electric"
                                />
                              </RadioGroup>
                              {errors.fuelType && (
                                <FormHelperText className="mt-0 mb-2" error>
                                  Fuel Type is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend" className={localStyles.legend}>
                                Miscellaneous
                              </FormLabel>
                              <FormControlLabel
                                label="Spare Keys"
                                control={
                                  <Checkbox
                                    id="spareKeys"
                                    value={values.spareKeys}
                                    defaultChecked={values.spareKeys}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                }
                              />
                              <FormControlLabel
                                label="Service Book"
                                control={
                                  <Checkbox
                                    id="serviceBook"
                                    value={values.serviceBook}
                                    defaultChecked={values.serviceBook}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                }
                              />
                              <FormControlLabel
                                label="Maintenance Plan"
                                control={
                                  <Checkbox
                                    id="maintenancePlan"
                                    value={values.maintenancePlan}
                                    defaultChecked={values.maintenancePlan}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                }
                              />
                              <FormControlLabel
                                label="Warranty"
                                control={
                                  <Checkbox
                                    id="warranty"
                                    value={values.warranty}
                                    defaultChecked={values.warranty}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Container>
                      </>
                    }

                    <Grid item xs={12} container justify="flex-end">
                      <Button className={classes.btn} variant="contained" color="primary" type="submit">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default VehicleSpecsForm;
