import React, { FC, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { Switch } from "formik-material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { ReactNode } from "react-transition-group/node_modules/@types/react";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(20, 0, 6),
    paddingTop: "0",
  },
  dividerTitle: {
    backgroundColor: "#06141f",
    width: "33%",
    marginBottom: "0px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  url: {
    color: "#7c7c7c",
    textDecoration: "none",
    "&:hover": {
      color: "#1f2532",
      textDecoration: "none",
    },
  },
  detailsParagraph: {
    textAlign: "left",
    fontSize: "1.8rem",
  },
  link: {
    backgroundColor: "transparent",
    marginBottom: "1rem",
    color: "#3f51b5",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.8,
    },
    //fontWeight: '700',
    fontWeight: 700,
  },
  typoHeading: {
    textAlign: "center",
    color: "textPrimary",
    fontWeight: 500,
  },
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },

  button: {
    width: "100%",
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: "uppercase",
  },
  noSideDetails: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface Props {
  base_uri: string;
  dealerId: number;
  title?: string;
  variant: "standard";
  sideBarDetailsHeader?: string;
  sideBarDetails?: ReactNode;
  isCashBuyer?: string | null;
  variantId?: number;
  leadTypeId?: number;
  dealerName: string;
  questions?: any[];
}

interface Question {
  name: string;
  labelComponent: any;
  correct: boolean;
  order: number;
  answer?: boolean;
}

interface LeadInput {
  leadStatusId?: number;
  dealerId?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  leadSourceId?: number;
  TypeCode?: string;
  notes?: string;
  leadTypeId?: number;
  dateUpdated?: string;
}

interface LeadVehicleInput {
  LeadId?: number;
  VariantId?: number;
}

const Parts: FC<Props> = ({
  base_uri,
  dealerName,
  dealerId,
  variant,
  questions,
  title,
  isCashBuyer,
  variantId,
  leadTypeId,
}) => {
  const classes = useStyles();

  const defaultQuestions = [
    {
      name: "contactMe",
      labelComponent: <span>I allow {dealerName} Motors to contact me</span>,
      correct: true,
      order: 1,
    },
    {
      name: "popi",
      labelComponent: (
        <span>
          By submitting this form I agree to the{" "}
          <Link component={RouterLink} to="/terms" className={classes.link}>
            terms and conditions
          </Link>{" "}
          and{" "}
          <Link component={RouterLink} to="/privacy" className={classes.link}>
            privacy policies.
          </Link>
        </span>
      ),
      correct: true,
      order: 4,
    },
  ];

  if (!questions) {
    questions = defaultQuestions;
  }

  const [questionAnswers, setQuestionAnswers] = useState<Question[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [contactMe, setContactMe] = useState(false);
  const [tsAndCs, setTsAndCs] = useState(false);
  const [dealer, setDealer] = useState(null);

  useEffect(() => {}, []);

  let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  const values = {
    name: "",
    phone: "",
    email: "",
  };

  const DEALERNAME = "DealerNamePlaceholder";
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required").matches(phoneRegExp, "Phone number is not valid"),
  });

  const checkValid = (validForm: boolean) => {
    var validAnswers = true;
    if (questions) {
      for (var idx in questions) {
        var itm = questions[idx];
        var answer = questions.filter((answer) => answer.name == itm.name)[0];

        if (itm.correct && !answer) {
          validAnswers = false;
        } else if (answer && itm.correct != answer.answer) {
          validAnswers = false;
        }
      }
    }

    return validForm && validAnswers;
  };

  const createVehicleLead = (values: any) => {
    let vehicleParam: LeadVehicleInput = {};
    vehicleParam.LeadId = values?.leadId;
    vehicleParam.VariantId = values?.variantId;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: "POST",
      url: `${base_uri}/leadVehicles`,
      data: vehicleParam,
      cancelToken: source.token,
    })
      .then((responseSecond) => {
        setLoader(false);
        enqueueSnackbar(`Successfully sent information`, { variant: "success" });
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        enqueueSnackbar("Unable to get complete the request", { variant: "error" });
        //setLoader(false);
      });
  };

  function createLead(values: any) {
    setLoader(true);

    let params: LeadInput = {};

    params.leadStatusId = 1;
    params.dealerId = dealerId;
    params.name = values.name;
    params.firstName = values.name;
    params.lastName = "";
    params.phoneNumber = values.phone;
    params.emailAddress = values.email;
    params.leadSourceId = 1;
    params.TypeCode = "NEW";
    params.notes = isCashBuyer ? "Cash buyer" : "";
    params.leadTypeId = leadTypeId;

    params.dateUpdated = new Date().toISOString();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: "POST",
      url: `${base_uri}/leads`,
      data: params,
      cancelToken: source.token,
    })
      .then((response) => {
        //@ts-ignore
        fbq("track", "Lead"); // eslint-disable-line

        if (variantId) {
          values.leadId = response.data.id;

          createVehicleLead(values);
        } else {
          setLoader(false);
          enqueueSnackbar(`Successfully sent information`, { variant: "success" });
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        enqueueSnackbar("Unable to complete the request", { variant: "error" });
        setLoader(false);
      });
  }

  function setQuestion(question: Question) {
    var answer = false;
    var existingAnswer = questionAnswers.filter((itm: Question) => itm.name === question.name);

    if (existingAnswer && existingAnswer.length > 0) {
      answer = existingAnswer[0].answer!;
    }

    question.answer = answer ? false : true;

    questionAnswers.push(question);
    setQuestionAnswers(questionAnswers);
  }

  return (
    <React.Fragment>
      {title && (
        <>
          <Typography gutterBottom variant="h5" className={classes.typoHeading}>
            {title}
          </Typography>
          <Divider className={classes.dividerTitle} />
        </>
      )}
      <MuiThemeProvider>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Card className={classes.content} elevation={0}>
              <CardContent>
                <Formik
                  isInitialValid={false}
                  initialValues={values}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    createLead(values);
                  }}
                >
                  {(props) => {
                    let {
                      values: { name, phone, email },
                      touched,
                      errors,
                      isValid,
                      submitForm,
                      setFieldTouched,
                      setFieldValue,
                    } = props;
                    return (
                      <Form>
                        <Grid container /*className={classes.root}*/ direction="row">
                          <Grid item /*className={classes.stepPaddingButton}*/ xs={12} md={12}>
                            <TextField
                              id="name"
                              name="name"
                              label="Name"
                              value={name}
                              fullWidth
                              variant="outlined"
                              helperText={touched.name ? errors.name : ""}
                              error={touched.name && Boolean(errors.name)}
                              onChange={(event) => {
                                setFieldValue("name", event.target.value);
                                setFieldTouched("name", true, false);
                              }}
                            />
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                            <TextField
                              id="email"
                              name="email"
                              type="email"
                              label="Email"
                              fullWidth
                              value={email}
                              variant="outlined"
                              helperText={touched.email ? errors.email : ""}
                              error={touched.email && Boolean(errors.email)}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value);
                                setFieldTouched("email", true, false);
                              }}
                            />{" "}
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                            <TextField
                              id=" phone"
                              name="phone"
                              type="tel"
                              label="Cellphone"
                              fullWidth
                              value={phone}
                              variant="outlined"
                              helperText={touched.phone ? errors.phone : ""}
                              error={touched.phone && Boolean(errors.phone)}
                              onChange={(event) => {
                                setFieldValue("phone", event.target.value);
                                setFieldTouched("phone", true, false);
                              }}
                            />
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                            <Typography gutterBottom variant="h6" className={classes.typoHeading}>
                              Parts Enquiry
                            </Typography>

                            <Box mb={2}>
                              <TextField
                                id="enquiry"
                                name="enquiry"
                                placeholder="enter equiry details here"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                variant="standard"
                              />
                            </Box>
                          </Grid>
                          {questions &&
                            questions.map((itm: Question) => (
                              <Grid item className={classes.flex} xs={12} md={12} lg={12}>
                                {/* @ts-ignore */}
                                <FormControlLabel
                                  control={
                                    <Field
                                      component={Switch}
                                      color="primary"
                                      type="checkbox"
                                      name={itm.name}
                                      onClick={() => {
                                        setQuestion(itm);
                                      }}
                                    />
                                  }
                                />
                                {itm.labelComponent}
                              </Grid>
                            ))}
                          <Grid container direction="row">
                            <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                              {!loader && (
                                <Button
                                  className={classes.button}
                                  variant={"contained"}
                                  color="primary"
                                  disabled={!checkValid(isValid)}
                                  onClick={() => {
                                    submitForm();
                                  }}
                                >
                                  {isCashBuyer ? "Order Now" : "Contact Me"}
                                </Button>
                              )}
                              {loader == true && (
                                <Button
                                  className={classes.button}
                                  variant={"contained"}
                                  color="primary"
                                  disabled={true}
                                >
                                  <CircularProgress />
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default Parts;
