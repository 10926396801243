import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 700,
  },

  flexCard: {
    display: "flex",

    "@media (max-width: 900px)": {
      flexDirection: "column",
    },
  },

  content: {
    backgroundColor: theme.palette.background.paper,
  },

  headingHolder: {
    backgroundColor: "#06141f",
    textAlign: "center",
    marginBottom: "0px",
    color: "white",
    margin: "30px auto",
  },

  img: {
    width: "100%",
    height: "fit-content",
  },

  typoheading: {
    textAlign: "center",
    color: "textPrimary",
    fontWeight: 500,
  },
}));

interface DealerCardProps {
  id?: number;
  name: any;
  image: any;
  number: any;
  branch: any;
  weekHours: any;
  satHours: any;
  location: any;
}

const DealerCard: FC<DealerCardProps> = ({ name, image, branch, weekHours, number, satHours, location }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div>
        <Grid container className={classes.content}>
          <Grid item xs={12} md={12} lg={12}>
            <Box className={classes.headingHolder} mb={1} mt={1}>
              <Box pt={1}>{name}</Box>
              <Box pb={1}>{branch}</Box>
            </Box>

            <Box mb={1} mt={1}>
              {number}
            </Box>

            <Box mb={1} mt={1}>
              <Box>{weekHours}</Box>
              <Box>{satHours}</Box>
            </Box>

            <Box mb={1} mt={1}>
              <img alt="" src={image.toString()} className={classes.img} />
            </Box>

            <Box>{location}</Box>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default DealerCard;
